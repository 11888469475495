/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable no-continue */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import axios from "utils/axios";
import { apiGetAllAccounts } from "api/user";
import { debounce } from "lodash";
import { Flex, Select, Tooltip, Typography } from "antd";
import numeral from "numeral";
import { MARKETPLACES_CONFIG } from "constants/asinzen-api/common";

const { Text } = Typography;

const RenderAsin = ({ data: { asin, url } }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
      {asin}
    </a>
  );
};

const RenderTitle = ({ value, data: { url } }) => {
  return (
    <Tooltip title={value ? value : "N/A"} placement="top">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value ? value : "N/A"}
      </a>
    </Tooltip>
  );
};

const RenderImage = ({ value }) => {
  return <img style={{ height: 36 }} src={value} />;
};

const RenderMoney = ({ value, prefix, data: { marketplace } }) => {
  if (!value || !marketplace || !MARKETPLACES_CONFIG[marketplace]) {
    return "--/-";
  }
  const { iconMoney } = MARKETPLACES_CONFIG[marketplace];
  const text = numeral(value).format("0,0.00");
  if (value < 0) {
    return (
      <Text type="danger">
        {prefix || iconMoney}
        {text}
      </Text>
    );
  }
  return (
    <Text type="success">
      {prefix || iconMoney}
      {text}
    </Text>
  );
};

const listTime = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 30 days", value: "last30days" },
  { label: "All history", value: "all" }
];

const BuySellHistory = () => {
  const gridRef = useRef(null);
  const detailGrid = useRef({});
  const stateRef = useRef({
    time: null,
    account: null
  });
  const limit = 10;

  const [agGridApi, setAgGridApi] = useState(null);

  const [state, setState] = useState({
    time: "today",
    listAccount: [{ label: "All accounts", value: "all" }],
    account: "all"
  });

  const showListAccounts = allAccounts => {
    const list = [{ label: "All accounts", value: "all" }];
    // eslint-disable-next-line
    for (const account of allAccounts) {
      list.push({ label: account.email, value: account._id });
    }
    setState(prevState => ({
      ...prevState,
      listAccount: list
    }));
  };

  const onGridReady = params => {
    const gridApi = params.api;
    setAgGridApi(gridApi);

    gridApi.setColumnDefs(columnDefs);
    gridApi.sizeColumnsToFit();
    gridApi.closeToolPanel();
    onGetProducts();
  };

  const onGetProducts = () => {
    const dataSource = getServerSideDatasource();
    agGridApi?.setServerSideDatasource(dataSource);
  };

  const moneyFormatter = ({ value, data: { marketplace } }) => {
    const { iconMoney } = MARKETPLACES_CONFIG[marketplace];
    if (!value || !marketplace || !MARKETPLACES_CONFIG[marketplace]) {
      return "--/-";
    }

    const text = numeral(value).format("0,0.00");
    return `${iconMoney}${text}`;
  };

  const [columnDefs] = useState([
    {
      headerName: "",
      field: "_id",
      width: 20,
      cellRenderer: "agGroupCellRenderer"
    },
    {
      headerName: "ASIN",
      field: "asin",
      width: 200,
      cellRenderer: RenderAsin
    },
    {
      headerName: "Product Image",
      field: "image",
      cellRenderer: RenderImage
    },

    {
      headerName: "Product Title",
      field: "title",
      cellRenderer: RenderTitle
    }
  ]);

  const gridOptions = Object.create({
    rowModelType: "serverSide",
    frameworkComponents: {
      RenderAsin,
      RenderImage,
      RenderTitle
    },
    rowSelection: "multiple",
    defaultColDef: {
      sortable: false,
      resizable: false,
      filter: false,
      suppressMenu: true,
      suppressColumnsToolPanel: true
    },
    domLayout: "autoHeight",
    cacheBlockSize: limit,
    overlayNoRowsTemplate: "No results found",
    detailCellRendererParams: {
      detailGridOptions: {
        frameworkComponents: {
          RenderMoney
        },
        columnDefs: [
          {
            headerName: "Sell Price",
            field: "sellPrice",
            sortable: true,
            suppressMenu: true,
            valueFormatter: moneyFormatter
          },
          {
            headerName: "Buy Cost",
            field: "buyCost",
            sortable: true,
            suppressMenu: true,
            valueFormatter: moneyFormatter
          },
          {
            headerName: "Profit",
            field: "profit",
            sortable: true,
            suppressMenu: true,
            cellRenderer: RenderMoney
          },
          {
            headerName: "ROI",
            field: "roi",
            sortable: true,
            suppressMenu: true,
            cellRenderer: RenderMoney
          },
          {
            headerName: "Margin",
            field: "margin",
            sortable: true,
            suppressMenu: true,
            cellRenderer: RenderMoney,
            cellRendererParams: {
              prefix: "%"
            }
          },
          {
            headerName: "Total Fees",
            field: "totalFees",
            sortable: true,
            suppressMenu: true,
            valueFormatter: moneyFormatter
          },
          {
            headerName: "Total Costs",
            field: "totalCosts",
            sortable: true,
            suppressMenu: true,
            valueFormatter: moneyFormatter
          },
          {
            headerName: "Total Fees & Costs",
            field: "totalFeesAndCosts",
            sortable: true,
            suppressMenu: true,
            valueFormatter: moneyFormatter
          },
          {
            headerName: "Fulfillment",
            field: "fulfillmentType",
            sortable: true,
            suppressMenu: true
          },
          {
            headerName: "Email",
            field: "email",
            sortable: true,
            suppressMenu: true,
            width: 300
          },
          {
            headerName: "Source",
            field: "source",
            sortable: true,
            suppressMenu: true
          },
          {
            headerName: "Time",
            field: "createdAt",
            sortable: false,
            suppressMenu: true,
            valueFormatter: ({ value }) =>
              !value ? "--/-" : moment(value).format("MMM DD, YYYY HH:mm:ss")
          }
        ],
        onFirstDataRendered(params) {
          params.api.sizeColumnsToFit();
        },
        pagination: false
        // paginationPageSize: 5
      },
      getDetailRowData: async params => {
        try {
          const { asin } = params.data;
          const { time, account } = stateRef.current;
          const endTime = moment().unix();
          let startTime = 0;
          switch (time) {
            case "today":
              startTime = moment()
                .startOf("day")
                .unix();
              break;
            case "yesterday":
              startTime = moment()
                .startOf("day")
                .unix();
              break;
            case "last30days":
              startTime = moment()
                .subtract(30, "day")
                .startOf("day")
                .unix();
              break;
            default:
              break;
          }
          const queryParams = {
            asin
          };

          if (account && account !== "all") {
            queryParams.uid = account;
          }

          if (startTime > 0 && endTime > 0) {
            queryParams.st = startTime;
            queryParams.et = endTime;
          }

          const resp = await axios.get("/bs-history-by-asin", {
            params: queryParams
          });
          const { data } = resp.data;
          params.successCallback(data);
        } catch (e) {
          console.log(e);
          params.successCallback([]);
        }
        // setTimeout(() => {
        //   const { data, node } = params;
        //   detailGrid.current[data.asin] = node.detailGridInfo.api;
        //   onGetDetailData(data.asin, 5);
        // }, 200);
      }
    }
  });

  // const onGetDetailData = (asin, limit) => {
  //   const dataSource = getDetailDatasource(asin, limit);
  //   detailGrid.current[asin].setServerSideDatasource(dataSource);
  // };

  const getServerSideDatasource = () => {
    return {
      getRows: debounce(async params => {
        try {
          const { time, account } = stateRef.current;
          const endTime = moment().unix();
          let startTime = 0;
          switch (time) {
            case "today":
              startTime = moment()
                .startOf("day")
                .unix();
              break;
            case "yesterday":
              startTime = moment()
                .startOf("day")
                .unix();
              break;
            case "last30days":
              startTime = moment()
                .subtract(30, "day")
                .startOf("day")
                .unix();
              break;
            default:
              break;
          }

          const { startRow } = params.request;
          const page = startRow === 0 || startRow === undefined ? 1 : startRow / limit + 1;
          const queryParams = {
            p: page,
            limit
          };

          if (account && account !== "all") {
            queryParams.uid = account;
          }

          if (startTime > 0 && endTime > 0) {
            queryParams.st = startTime;
            queryParams.et = endTime;
          }
          const resp = await axios.get("/bs-history-asins", {
            params: queryParams
          });

          const { data, total } = resp.data;
          params.success({
            rowData: data || [],
            rowCount: total
          });
        } catch (e) {
          params.success({
            rowData: [],
            rowCount: 0
          });
        } finally {
          detailGrid.current = {};
        }
      }, 500)
    };
  };

  // const getDetailDatasource = (asin, limit) => {
  //   return {
  //     getRows: async params => {
  //       try {
  //         const { time, account } = state;
  //         let endTime = 0;
  //         let startTime = 0;
  //         switch (time) {
  //           case "today":
  //             startTime = moment()
  //               .startOf("day")
  //               .unix();
  //             endTime = moment().unix();
  //             break;
  //           case "yesterday":
  //             startTime = moment()
  //               .startOf("day")
  //               .unix();
  //             break;
  //           case "last30days":
  //             startTime = moment()
  //               .subtract(30, "day")
  //               .startOf("day")
  //               .unix();
  //             endTime = moment().unix();
  //             break;
  //           default:
  //             break;
  //         }

  //         const { startRow } = params.request;
  //         const page = startRow === 0 || startRow === undefined ? 1 : startRow / limit + 1;
  //         const queryParams = {
  //           p: page,
  //           limit,
  //           asin
  //         };

  //         if (account && account !== "all") {
  //           queryParams.uid = account;
  //         }

  //         if (startTime > 0 && endTime > 0) {
  //           queryParams.st = startTime;
  //           queryParams.et = endTime;
  //         }
  //         const resp = await axios.get("/bs-history-by-asin", {
  //           params: queryParams
  //         });

  //         const { data, total } = resp.data;
  //         params.success({
  //           rowData: data,
  //           rowCount: total
  //         });
  //       } catch (e) {
  //         params.success({
  //           rowData: [],
  //           rowCount: 0
  //         });
  //       }
  //     }
  //   };
  // };

  const getRowNodeId = params => params.data.asin;

  const onChangeTime = value =>
    setState(prevState => ({
      ...prevState,
      time: value
    }));

  const onChangeAccount = value =>
    setState(prevState => ({
      ...prevState,
      account: value
    }));

  useEffect(() => {
    onGetProducts();
  }, [state.listAccount, state.time, state.account]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    apiGetAllAccounts().then(data => showListAccounts(data));
  }, []);

  return (
    <Flex vertical gap="middle">
      <Flex justify="space-between" align="center">
        <Text type="danger">*The Buy Sell Historical records are saved for 540 days only</Text>
      </Flex>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="middle">
          <div>Filter by:</div>
          <div>Time</div>
          <Select
            onChange={onChangeTime}
            value={state.time}
            options={listTime.map(item => ({
              label: item.label,
              value: item.value
            }))}
          />
          <div>and Account email</div>
          <Select
            onChange={onChangeAccount}
            value={state.account}
            options={state.listAccount.map(item => ({
              label: item.label,
              value: item.value
            }))}
          />
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
        <AgGridReact
          ref={gridRef}
          animateRows
          enableRangeSelection
          pagination
          paginationPageSize={limit}
          getRowId={getRowNodeId}
          gridOptions={gridOptions}
          sideBar="columns"
          localeText={{ columns: "Configure Columns" }}
          onGridReady={onGridReady}
          masterDetail
          detailRowHeight={500}
        />
      </div>
    </Flex>
  );
};

export default BuySellHistory;
