/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { socket } from "./../../../socket";
import _find from "lodash/find";
import PropTypes from "prop-types";
import moment from "moment";

import { connectAutoDispatch } from "redux/reduxConnect";
import {
  getProductPlanRequest,
  getCurrentPluginsRequest,
  getCurrentPluginsAddedRequest,
  userBuyPluginsRequest
} from "redux/actions/PlanActions";
import { getUserInfoAppRequest } from "redux/actions/UserActions";
import { stripeGetPricesRequest } from "redux/actions/StripeActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import { planInitState, planPropTypes } from "redux/reducers/PlanReducer";
import { enqueueSnackbar, closeSnackbar } from "notistack";

import PlanUICommon from "components/plans/asin-tracker";
import { findIndex, groupBy } from "lodash";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import isEqual from "lodash/isEqual";

import { apiGetCheckoutUrl, apiGetPortalUrl, apiUpdateSubscription } from "api";
import AZInshgitIcon from "static/img/azinsight-scanez.png";
import AZAlertIcon from "static/img/azalert.png";
import AZAPIIcon from "static/img/favicon.ico";
import constants from "utils/constants";
import AZAlertSubAccounts from "./AZAlertSubAccounts";
import AZInsightPlan from "./AZInsightPlan";
import AZAlertPlan from "./AZAlertPlan";
import AZInsightSubAccounts from "./AZInsightSubAccounts";
import { AZApiPlanSubscription } from "../../app/asinzen-api";
import { AZApiPlanOneTime } from "../../app/asinzen-api";
import propTypes from "eslint-plugin-react/lib/rules/prop-types";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Grid as GridAntd, Dropdown, Space, Typography, Tabs, Flex, Modal, Spin } from "antd";
import type { MenuProps } from "antd";

const intervalLables = {
  year: "Annually",
  month: "Monthly"
};

const titlePlanAzinsight = {
  HAZability_PLAN_MONTHLY: "Starter Plan",
  HAZability_PLAN: "Starter Plan",
  AZInsight_PLAN_MONTHLY: "Advanced Plan",
  AZInsight_PLAN: "Advanced Plan"
};

const SUB_ACOUNTS_PLUGINS = [
  "AZINSIGHT_PRO_ADDITIONAL_LOGIN",
  "AZINSIGHT_PRO_ADDITIONAL_LOGIN_MONTHLY",
  "AZINSIGHT_PLUS_ADDITIONAL_LOGIN",
  "AZINSIGHT_PLUS_ADDITIONAL_LOGIN_MONTHLY"
];
const AZINSIGHT_PLUGINS_EXCLUDES = [...SUB_ACOUNTS_PLUGINS, "AZIPALERT"];

/** AZAPI PLANS */
const AZAPI_PLUGINS_SUBSCRIPTION = ["ASINZEN_API_MONTHLY", "ASINZEN_API_YEARLY"];
const AZAPI_PLUGINS_ONETIME = ["ASINZEN_API_ONETIME"];

const { useBreakpoint } = GridAntd;

const ListProduct = ({
  userState,
  planState,
  stripeState,
  getProductPlanRequest,
  stripeGetPricesRequest,
  getCurrentPluginsRequest,
  getCurrentPluginsAddedRequest,
  getUserInfoAppRequest
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const queryTab = query.get("tab") ? query.get("tab").toLowerCase() : null;
  const queryMenu = query.get("menu") ? query.get("menu").toLowerCase() : null;
  const queryPlugin = query.get("plugin");
  const screens = useBreakpoint();

  useEffect(() => {
    getProductPlanRequest();
    stripeGetPricesRequest();
    getCurrentPluginsRequest();
    getCurrentPluginsAddedRequest();
  }, []);

  const [azinsightContent, setAzinsightContent] = useState(null);
  const [azAlertContent, setAzAlertContent] = useState(null);
  const [azApiContent, setAzApiContent] = useState(null);
  const [paidPlugins, setPaidPlugins] = useState(null);
  const [azAlertPlan, setAzAlertPlan] = useState(null);
  const [azAlertPlugins, setAzAlertPlugins] = useState(null);
  const [pluginsAZInsightSubAccount, setPluginsAZInsightSubAccount] = useState(null);
  const [pluginsAZAlertSubAccount, setPluginsAZAlertSubAccount] = useState(null);
  const [pluginsAZApiSubscriptionState, setPluginsAZApiSubscriptionState] = useState(null);
  const [pluginsAZApiOnetime, setPluginsAZApiOnetime] = useState(null);
  const [childrenAsinzenApiTab, setChildrenAsinzenApiTab] = useState(null);
  const [childrenTab, setChildrenTab] = useState();
  const [subcribeInfo, setSubcribeInfo] = useState(null);
  const [state, setState] = useState({
    planTab: "AZINSIGHT",
    interval: "Year",
    pluginSubMenu: "PLAN",
    checkoutUrl: "",
    openCouponCodeWarning: false,
    invalidCouponCodes: [],
    disabled: false,
    openRenewConfirmation: false,
    openRenewConfirmation2: false,
    selectedPlan: null,
    openPopupMws1: false,
    openPopupMws2: false,
    openPopupAsinzenTokenMws: false,
    openErrorDialog: false,
    loading: false
  });

  const prevPlanStateRef = useRef(planState);

  const { userInfo } = userState;
  const { currentPlugins, asinTrackerPlugins, currentPlans, bcpPlugins, pluginsAdded } = planState;

  const changeInterval = interval => {
    setState(prevState => ({
      ...prevState,
      interval
    }));
  };

  const closeCouponCodeWarning = () => {
    setState(prevState => ({
      ...prevState,
      openCouponCodeWarning: false,
      invalidCouponCodes: [],
      checkoutUrl: "",
      disabled: false
    }));
  };

  const openRenewConfirmation = (e, selectedPlan) => {
    e && e.preventDefault();
    if (selectedPlan.cancel) {
      updateSubscription(selectedPlan);
    } else {
      setState(prevState => ({
        ...prevState,
        openRenewConfirmation: true,
        selectedPlan
      }));
    }
  };

  const closeRenewConfirmation = () => {
    setState(prevState => ({
      ...prevState,
      openRenewConfirmation: false,
      selectedPlan: null
    }));
  };

  const closeRenewConfirmation2 = () => {
    const { userInfo } = userState;
    localStorage.setItem(constants.KEY_LOCAL_PLANS, userInfo.stripeSubId);
    setState(prevState => ({
      ...prevState,
      openRenewConfirmation2: false,
      selectedPlan: null
    }));
  };

  const comfirmRenew = () => {
    const { selectedPlan } = state;
    closeRenewConfirmation();
    updateSubscription(selectedPlan);
  };

  const comfirmRenew2 = () => {
    const { userInfo } = userState;
    updateSubscription({
      subId: userInfo.stripeSubId,
      cancel: !userInfo.isCanceledAccount,
      reason: "",
      title: titlePlanAzinsight[userInfo.stripePriceKey]
    });
    closeRenewConfirmation2();
  };

  const openPopupMws1 = () => {
    setState(prevState => ({
      ...prevState,
      openPopupMws1: true,
      loading: false
    }));
  };

  const openPopupMws2 = () => {
    setState(prevState => ({
      ...prevState,
      openPopupMws2: true
    }));
  };

  const openPopupAsinzenTokenMws = () => {
    setState(prevState => ({
      ...prevState,
      openPopupAsinzenTokenMws: true,
      loading: false
    }));
  };

  const closePopupMws1 = () => {
    setState(prevState => ({
      ...prevState,
      openPopupMws1: false
    }));
  };

  const closePopupMws2 = () => {
    setState(prevState => ({
      ...prevState,
      openPopupMws2: false
    }));
  };

  const closePopupAsinzenTokenMws = () => {
    setState(prevState => ({
      ...prevState,
      openPopupAsinzenTokenMws: false
    }));
  };

  const updateSubscription = async data => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }));
    try {
      await apiUpdateSubscription(data).then(() =>
        setState(prevState => ({
          ...prevState,
          selectedPlan: data
        }))
      );
      window.location.href = getReturnUrl();
    } catch (e) {
      if (e.response && e.response.status === 500) {
        openErrorDialog();
      }
      setState(prevState => ({
        ...prevState,
        loading: false
      }));
    }
  };

  const openUrl = url => {
    window.location.href = url;
  };

  const getReturnUrl = () => {
    const { planTab, pluginSubMenu } = state;
    let menu = "";
    switch (pluginSubMenu) {
      case "SUB_ACCOUNTS":
        menu = "sub";
        break;
      case "SUBSCRIPTION":
        menu = "subscription";
        break;
      case "ONE_TIME_PAYMENT":
        menu = "onetime";
      default:
        menu = "plan";
    }

    return `${window.location.origin}${
      window.location.pathname
    }?tab=${planTab.toLowerCase()}&menu=${menu}`;
  };

  const openStripePortal = async () => {
    try {
      const resp = await apiGetPortalUrl({
        returnUrl: getReturnUrl()
      });
      if (resp.url) {
        window.location.href = resp.url;
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        openErrorDialog();
      }
    }
  };

  const openCheckoutUrl = async (item, requiredMws = false) => {
    try {
      setState(prevState => ({
        ...prevState,
        loading: true
      }));
      const resp = await apiGetCheckoutUrl({
        price: item.priceId,
        mode: item.mode,
        quantity: 1,
        returnUrl: getReturnUrl()
      });

      if (resp.status === "SUBSCRIPTION_CHANGED") {
        window.location.href = getReturnUrl();
        return;
      }

      if (resp.status === "NO_MWS") {
        requiredMws ? openPopupAsinzenTokenMws() : openPopupMws1();
        return;
      }

      if (resp.invalidCouponCodes && resp.invalidCouponCodes.length) {
        setState(prevState => ({
          ...prevState,
          invalidCouponCodes: resp.invalidCouponCodes,
          openCouponCodeWarning: true,
          checkoutUrl: resp.url,
          loading: false
        }));
      } else {
        openUrl(resp.url);
      }
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 500) {
        openErrorDialog();
      }
      closeLoading();
    }
  };

  const getPlugins = (plugins, showAZAlert) => {
    const { userInfo } = userState;
    const { pluginsAdded } = planState;
    if (!plugins || !plugins.length) return [];
    return plugins.filter(d => {
      if (showAZAlert) {
        if (d.code === "AZALERT_EXTRA_LOGIN") {
          return true;
        } else {
          return false;
        }
      } else if (
        (["hazmat", "basic"].includes(userInfo.productType) && d.code === "AZIPALERT") ||
        d.code === "AZALERT_EXTRA_LOGIN"
      ) {
        return false;
      }
      return true;
    });
  };

  const cancelSubscription = () => {
    const { userInfo } = userState;
    document.getElementById(`cancel-sub-${userInfo.stripePriceKey}`).click();
  };

  const closeLoading = () => {
    setState(prevState => ({
      ...prevState,
      loading: false
    }));
  };

  const openErrorDialog = () => {
    setState(prevState => ({
      ...prevState,
      openErrorDialog: true
    }));
  };

  const openChatWithUs = () => {
    if (window.fcWidget) {
      window.fcWidget.open();
    } else {
      console.error("FreshChat widget is not initialized");
    }
  };

  const closeErrorDialog = support => {
    setState(prevState => ({
      ...prevState,
      openErrorDialog: false
    }));
    if (support) {
      openChatWithUs();
    }
  };

  useEffect(() => {
    const reloadPage = () => {
      window.location.href = getReturnUrl();
    };

    socket.on("message", payload => {
      console.log(payload);
    });
    socket.on("subscription:updated", payload => {
      reloadPage();
    });

    socket.on("subscription:deleted", payload => {
      reloadPage();
    });

    const { userInfo } = userState;
    if (
      userInfo.stripeSubId &&
      !isEqual(localStorage.getItem(constants.KEY_LOCAL_PLANS), userInfo.stripeSubId)
    ) {
      setState(prevState => ({ ...prevState, openRenewConfirmation2: true }));
    }

    return () => {
      socket.close();
      setState(prevState => ({ ...prevState, openRenewConfirmation2: false }));
    };
  }, [userState]);

  useEffect(() => {
    if (
      prevPlanStateRef.current.callingUserBuyPlugin &&
      !planState.callingUserBuyPlugin &&
      !planState.errorMessage.code
    ) {
      enqueueSnackbar("Thank you! Please refresh your browser for the new plugin to show up", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 5000
      });
    }

    if (
      prevPlanStateRef.current.callingUserBuyPlugin &&
      !planState.callingUserBuyPlugin &&
      planState.errorMessage.code
    ) {
      enqueueSnackbar(planState.errorMessage.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 5000
      });
    }
  }, [planState.callingUserBuyPlugin]);

  useEffect(() => {
    if (!planState.callingGetListPlan && queryPlugin) {
      setTimeout(() => {
        const div = document.getElementById("AZ-Plugin");
        div.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }, [planState.callingGetListPlan]);

  useEffect(() => {
    let subscribe = null;
    setState(prev => ({ ...prev, loading: true }));
    if (userInfo.stripeSubId) {
      if (userInfo.isCanceledAccount === true && moment().isBefore(userInfo.nextSubscribeAt)) {
        subscribe = (
          <>
            <div>
              Your subscription is active until{" "}
              <strong>{moment(userInfo.nextSubscribeAt).format("MMMM DD, YYYY")}</strong> and will
              <strong> NOT</strong> auto-renew.
            </div>
            <div>
              If you wish to automatically renew your subscription, please{" "}
              <a
                href="#"
                onClick={e =>
                  openRenewConfirmation(e, {
                    subId: userInfo.stripeSubId,
                    cancel: false
                  })
                }
              >
                click here.
              </a>
            </div>
          </>
        );
      }

      if (!userInfo.isCanceledAccount) {
        subscribe = (
          <>
            <div>
              Your subscription is active until{" "}
              <strong>{moment(userInfo.nextSubscribeAt).format("MMMM DD, YYYY")}</strong> and will
              auto-renew.
            </div>
            <div>
              If you wish to cancel auto-renew on your subscription, please{" "}
              <a href="#" onClick={cancelSubscription}>
                click here.
              </a>
            </div>
          </>
        );
      }
    }
    setSubcribeInfo(subscribe);
  }, [userInfo]);

  useEffect(() => {
    setState(prev => ({ ...prev, loading: true }));
    const getPaidPlugins = () => {
      let plugins = getPlugins(currentPlugins.data);
      let paidPlugins = plugins.filter(item => item.price);
      paidPlugins = groupBy(paidPlugins, "parent");
      paidPlugins.ASIN_TRACKER = asinTrackerPlugins.data.filter(item => item.price);
      paidPlugins.BULK_CHECKER_PRO =
        (userInfo &&
          userInfo.listUserPlan &&
          findIndex(userInfo.listUserPlan, { id: "BULK_CHECKER_PRO" }) >= 0 &&
          bcpPlugins.data.filter(item => item.price)) ||
        [];
      setPaidPlugins(paidPlugins);
    };

    const getAzAlertPlan = () => {
      let azAlertPlan = _find(currentPlugins.data, { code: "AZIPALERT" });
      const price = stripeState.prices.data.find(
        price => azAlertPlan && price.metadata && price.metadata.key === azAlertPlan.code
      );
      if (!price) {
        return null;
      }

      let subscribed = false;
      if (pluginsAdded) {
        subscribed = !!pluginsAdded.find(item => item.pluginId._id === azAlertPlan._id);
      }

      setAzAlertPlan({
        ...azAlertPlan,
        canSubscribe: !subscribed,
        subscribed,
        priceId: price.id,
        key: price.metadata.key,
        amount: price.unit_amount / 100,
        mode: price.type === "recurring" ? "subscription" : "payment"
      });
    };

    const getAzAlertPlugins = () => {
      const data = getPlugins(currentPlugins.data, true);
      setAzAlertPlugins(data);
    };

    getPaidPlugins();
    getAzAlertPlan();
    getAzAlertPlugins();
  }, [stripeState, currentPlugins]);

  useEffect(() => {
    setState(prev => ({ ...prev, loading: true }));
    const pluginsAZInsightSubAccount = () => {
      const data = paidPlugins?.AZINSIGHT
        ? paidPlugins.AZINSIGHT.reduce((result, item) => {
            const plugin = stripeState.prices.data.find(p => p.metadata.key === item.code);
            if (plugin && SUB_ACOUNTS_PLUGINS.includes(item.code)) {
              result.push({
                priceId: plugin.id,
                ...item,
                ...plugin
              });
            }
            return result;
          }, [])
        : [];
      setPluginsAZInsightSubAccount(data);
    };

    const pluginsAZAlertSubAccount = () => {
      const data = azAlertPlugins?.reduce((result, item) => {
        const plugin = stripeState.prices.data.find(p => p.metadata.key === item.code);
        if (plugin) {
          result.push({
            priceId: plugin.id,
            ...item,
            ...plugin
          });
        }
        return result;
      }, []);

      setPluginsAZAlertSubAccount(data);
    };

    const pluginsAZApiSubscription = () => {
      const data = paidPlugins?.ASINZEN_API
        ? paidPlugins.ASINZEN_API.reduce((result, item) => {
            const plugin = stripeState.prices.data.find(p => p.metadata.key === item.code);
            if (plugin && AZAPI_PLUGINS_SUBSCRIPTION.includes(item.code)) {
              result.push({
                priceId: plugin.id,
                ...item,
                ...plugin
              });
            }
            return result;
          }, [])
        : [];
      setPluginsAZApiSubscriptionState(data);
    };

    const pluginsAZApiOnetime = () => {
      const data = paidPlugins?.ASINZEN_API
        ? paidPlugins.ASINZEN_API.reduce((result, item) => {
            const plugin = stripeState.prices.data.find(p => p.metadata.key === item.code);
            if (plugin && AZAPI_PLUGINS_ONETIME.includes(item.code)) {
              result.push({
                priceId: plugin.id,
                ...item,
                ...plugin
              });
            }
            return result;
          }, [])
        : [];
      setPluginsAZApiOnetime(data);
    };

    pluginsAZInsightSubAccount();
    pluginsAZAlertSubAccount();
    pluginsAZApiOnetime();
    pluginsAZApiSubscription();
  }, [paidPlugins, azAlertPlugins, stripeState]);

  const renderChildrenAzInsightTab = useCallback(() => {
    return [
      {
        label: "Plans",
        key: "PLAN",
        children: (
          <>
            {subcribeInfo && (
              <Typography
                style={{ fontSize: screens.sm ? 14 : 12, marginBottom: 20, textAlign: "center" }}
              >
                {subcribeInfo}
              </Typography>
            )}
            <AZInsightPlan
              interval={state.interval}
              changeInterval={changeInterval}
              intervalLables={intervalLables}
              openRenewConfirmation={openRenewConfirmation}
              openCheckoutUrl={openCheckoutUrl}
              openErrorDialog={openErrorDialog}
            />
          </>
        )
      },
      {
        label: "Sub-accounts",
        key: "AZINSIGHT_SUB_ACCOUNTS",
        children: (
          <AZInsightSubAccounts
            plugins={pluginsAZInsightSubAccount}
            intervalLables={intervalLables}
            openRenewConfirmation={openRenewConfirmation}
            openStripePortal={openStripePortal}
            openCheckoutUrl={openCheckoutUrl}
          />
        )
      }
    ];
  }, [pluginsAZInsightSubAccount, state.interval]);

  const renderChildrenAzAlertTab = useCallback(() => {
    return [
      {
        label: "Plans",
        key: "PLAN",
        children: <AZAlertPlan plan={azAlertPlan} openCheckoutUrl={openCheckoutUrl} />
      },
      {
        label: "Sub-accounts",
        key: "AZALERT_SUB_ACCOUNTS",
        children: (
          <AZAlertSubAccounts
            plan={azAlertPlan}
            plugins={pluginsAZAlertSubAccount}
            userInfo={userInfo}
            openCheckoutUrl={openCheckoutUrl}
          />
        )
      }
    ];
  }, [azAlertPlan, pluginsAZAlertSubAccount, userInfo]);

  const renderChildrenAsinzenApiTab = useCallback(() => {
    return [
      {
        label: "Subscription",
        key: "SUBSCRIPTION",
        children: (
          <AZApiPlanSubscription
            plugins={pluginsAZApiSubscriptionState}
            intervalLables={intervalLables}
            openRenewConfirmation={openRenewConfirmation}
            openStripePortal={openStripePortal}
            openCheckoutUrl={openCheckoutUrl}
          />
        )
      },
      {
        label: "One time payment",
        key: "ONE_TIME_PAYMENT",
        children: (
          <AZApiPlanOneTime
            plugins={pluginsAZApiOnetime}
            pluginsAdded={pluginsAdded}
            openCheckoutUrl={openCheckoutUrl}
          />
        )
      }
    ];
  }, [pluginsAZApiSubscriptionState, pluginsAZApiOnetime, pluginsAdded]);

  const planTabChange = (tab, submenu?) => {
    let pluginSubMenu = submenu || "PLAN";
    if (!submenu) {
      pluginSubMenu = tab === "ASINZENAPI" ? "SUBSCRIPTION" : pluginSubMenu;
    }
    setState(prevState => {
      return {
        ...prevState,
        pluginSubMenu,
        planTab: tab
      };
    });

    let menu = "plan";
    switch (pluginSubMenu) {
      case "SUB_ACCOUNTS":
        menu = "sub";
        break;
      case "SUBSCRIPTION":
        menu = "subscription";
        break;
      case "ONE_TIME_PAYMENT":
        menu = "onetime";
    }
    history.push(`?tab=${tab.toLowerCase()}&menu=${menu}`);
  };

  useEffect(() => {
    let planType = "AZINSIGHT";
    let pluginMenuItem = "PLAN";
    switch (queryTab) {
      case "azalert":
        planType = "AZALERT";
        break;
      case "asinzenapi":
        planType = "ASINZENAPI";
        pluginMenuItem = "SUBSCRIPTION";
        if (queryMenu === "onetime") {
          pluginMenuItem = "ONE_TIME_PAYMENT";
        }
        break;
      default:
        if (queryMenu === "sub") {
          pluginMenuItem = "SUB_ACCOUNTS";
        }
    }

    setState(prev => ({ ...prev, loading: false }));
    planTabChange(planType, pluginMenuItem);
  }, [queryTab, state.loading]);

  if (!stripeState || !stripeState.prices.loaded) {
    return null;
  }

  return (
    <Space direction="vertical" style={{ background: "white" }}>
      {!state.loading && (
        <Tabs
          centered
          size={screens.sm ? "large" : "small"}
          tabBarGutter={screens.md ? 100 : 35}
          onChange={planTabChange}
          activeKey={state.planTab}
          items={[
            {
              key: "AZINSIGHT",
              active: state.planTab === "AZINSIGHT",
              label: (
                <Flex gap="small" align="center" vertical>
                  <img style={{ width: 58 }} src={AZInshgitIcon} />
                  <Typography
                    style={{ fontSize: screens.sm ? 14 : 12, textTransform: "uppercase" }}
                  >
                    <strong>AZInsight & ScanEZ</strong>
                  </Typography>
                </Flex>
              ),
              children: (
                <Tabs
                  tabPosition={screens.md ? "left" : "top"}
                  type={screens.md ? "line" : "card"}
                  items={renderChildrenAzInsightTab()}
                />
              )
            },
            {
              key: "AZALERT",
              active: state.planTab === "AZALERT",
              label: (
                <Flex gap="small" align="center" vertical>
                  <img width="24" src={AZAlertIcon} />
                  <Typography
                    style={{ fontSize: screens.sm ? 14 : 12, textTransform: "uppercase" }}
                  >
                    <strong>AZAlert</strong>
                  </Typography>
                </Flex>
              ),
              children: (
                <Tabs
                  tabPosition={screens.md ? "left" : "top"}
                  type={screens.md ? "line" : "card"}
                  items={renderChildrenAzAlertTab()}
                />
              )
            },
            {
              key: "ASINZENAPI",
              active: state.planTab === "ASINZENAPI",
              label: (
                <Flex gap="small" align="center" vertical>
                  <img width="24" src={AZAPIIcon} />
                  <Typography
                    style={{ fontSize: screens.sm ? 14 : 12, textTransform: "uppercase" }}
                  >
                    <strong>ASINZEN TOKEN</strong>
                  </Typography>
                </Flex>
              ),
              children: (
                <Tabs
                  tabPosition={screens.md ? "left" : "top"}
                  type={screens.md ? "line" : "card"}
                  items={renderChildrenAsinzenApiTab()}
                />
              )
            }
          ]}
        />
      )}
      <Modal
        title={<strong>Warning</strong>}
        open={state.openCouponCodeWarning}
        okText="Checkout"
        onOk={() => openUrl(state.checkoutUrl)}
        onCancel={closeCouponCodeWarning}
        closable
        afterClose={closeCouponCodeWarning}
      >
        This coupon code <strong>{`${state.invalidCouponCodes.join(",")}`}</strong> is no longer
        valid or has expired or does not apply to the plan you've selected. If you need assistant,{" "}
        <a
          href="#"
          onClick={() => {
            closeCouponCodeWarning();
            openChatWithUs();
          }}
        >
          contact support
        </a>
        .
      </Modal>

      <Modal
        title={
          <Space>
            <Typography.Paragraph>
              <strong>Automatic renewal of your subscription fees</strong>
            </Typography.Paragraph>
          </Space>
        }
        open={state.openRenewConfirmation}
        okText="ENABLE AUTO RENEW"
        onOk={comfirmRenew}
        onCancel={closeRenewConfirmation}
      >
        By enrolling in the auto-renew program, you authorize asinzen to automatically charge your
        subscription fees to your credit card on file on the end of the billing cycle date and agree
        that you are the owner or authorized user of the accounts and payment method to be charged.
        By clicking the Agree button below, you also agree to the{" "}
        <a href="https://help.asinzen.com/autorenew" target="_blank">
          auto renew terms and conditions
        </a>
        .
      </Modal>
      <Modal
        title={<strong>Do you want to turn on auto-renew of your subscription?</strong>}
        open={state.openRenewConfirmation2}
        okText="Enable Auto Renew"
        onOk={comfirmRenew2}
        okButtonProps={{
          htmlType: "button",
          style: {
            marginTop: 5
          }
        }}
        cancelText="No thanks"
        onCancel={closeRenewConfirmation2}
        closable
        afterClose={closeRenewConfirmation2}
      >
        <Space direction="vertical">
          <p>
            Auto renew is turned OFF by default which will require you to manually renew your
            subscription by the end of the billing cycle date. If you wish to have asinzen
            automatically renew your subscription, click the Enable Auto Renew button below. You can
            turn the auto-renew feature OFF at any time from the Plans page.
          </p>
          <br />
          <p>
            By enrolling in the auto-renew program, you authorize asinzen to automatically charge
            your subscription fees to your credit card on file on your subscription's renewal date.
            You agree that you are the owner or authorized user of the account and payment method to
            be charged. By clicking the Enable Auto Renew button below, you also agree to the{" "}
            <a href="https://help.asinzen.com/autorenew" target="_blank">
              auto renew terms and conditions
            </a>
            .
          </p>
        </Space>
      </Modal>
      <Modal
        open={state.openPopupMws1}
        okText="Complete Amazon Integration"
        okButtonProps={{
          href: "/user/amazon-integration",
          htmlType: "button",
          style: {
            marginTop: 5
          }
        }}
        cancelText="I don't have an Amazon Pro seller account"
        onCancel={() => {
          closePopupMws1();
          openPopupMws2();
        }}
        closable
        afterClose={closePopupMws1}
      >
        <Space direction="vertical">
          <p>
            Our tools depend on a valid Amazon integration to give you the newest and most accurate
            data about the ASIN you are researching. At this time only sellers with an Amazon
            Professional Seller account can complete the Amazon integration, Individual seller
            accounts can not.
          </p>
          <p>
            If you do not have an Amazon Professional Seller account, we have to use a different
            process to provide you with the service, which is only available on the Monthly
            subscription plan. You can switch from the monthly to the annual subscription plan at
            any point after you upgrade your Amazon Seller account and complete the Amazon
            integration.
          </p>
          <p>
            If you have an Amazon Professional account select the "Complete the Amazon integration"
            option below to proceed.
          </p>
          <p>
            If you do not have a Professional Seller account, click "I don't have an Amazon Pro
            seller account" and subscribe to the Monthly plan.
          </p>
          <p>
            If you are unsure or need help to determine if your Amazon Seller Account is a
            Professional Account, click{" "}
            <a href="https://help.asinzen.com/identifymyselleraccount" target="_blank">
              here
            </a>
            .
          </p>
        </Space>
      </Modal>
      <Modal
        open={state.openPopupAsinzenTokenMws}
        okText="Complete Amazon Integration"
        okButtonProps={{
          href: "/user/amazon-integration",
          htmlType: "button",
          style: {
            marginTop: 5
          }
        }}
        onCancel={closePopupAsinzenTokenMws}
        closable
        afterClose={closePopupAsinzenTokenMws}
      >
        <Space direction="vertical">
          <p>
            Our tools depend on the Amazon integration to give you the newest and most accurate data
            about any ASIN you are researching. At this time only sellers with an Amazon
            Professional seller account can complete the Amazon integration, Individual seller
            accounts can not.
          </p>
          <p>
            If you have an Amazon Professional account select the "Complete the Amazon integration"
            option below to proceed. If you do not have a Professional seller account, You cannot
            purchase asinzen Token plans for our asinzen Insight Add-on.
          </p>
          <p>
            If you are unsure or need help determining if your Amazon seller account is a
            professional account,{" "}
            <a href="https://help.asinzen.com/identifymyselleraccount" target="_blank">
              click here
            </a>
            .
          </p>
        </Space>
      </Modal>
      <Modal
        open={state.openPopupMws2}
        okText="Subscribe to the Monthly plan"
        onOk={() => {
          closePopupMws2();
          changeInterval("Month");
        }}
        okButtonProps={{
          htmlType: "button",
          style: {
            marginTop: 5
          }
        }}
        onCancel={closePopupMws2}
        closable
        afterClose={closePopupMws2}
      >
        The asinzen tools depends heavily on Amazon API service to give you the newest and most
        accurate data about an ASIN you are researching. If you do not have access to this API, we
        have to use a different process which we only support under Monthly subscription plan.
      </Modal>
      <Spin fullscreen spinning={state.loading} />
      <Modal
        open={state.openErrorDialog}
        title={
          <strong style={{ color: "#f44336" }}>Oops! You broke the server! Just kidding!</strong>
        }
        okText="Talk to a Customer Champion"
        onOk={() => closeErrorDialog(true)}
        okButtonProps={{
          htmlType: "button",
          style: {
            marginTop: 5
          }
        }}
        cancelText="Close"
        onCancel={closeErrorDialog}
        closable
        afterClose={closeErrorDialog}
      >
        <p style={{ color: "#f44336" }}>
          Sorry, something caused a server error 500. Please click here to contact support to let us
          know that you’ve run into this issue so we can do our best to fix it.
        </p>
      </Modal>
    </Space>
  );
};

ListProduct.propTypes = {
  userState: PropTypes.shape(userPropTypes),
  planState: PropTypes.shape(planPropTypes),
  getProductPlanRequest: PropTypes.func,
  getCurrentPluginsRequest: PropTypes.func,
  getCurrentPluginsAddedRequest: PropTypes.func,
  getUserInfoAppRequest: PropTypes.func
};

ListProduct.defaultProps = {
  userState: userInitState,
  planState: planInitState,
  getProductPlanRequest: null,
  getCurrentPluginsRequest: null,
  getCurrentPluginsAddedRequest: null,
  getUserInfoAppRequest: null
};

const withConnect = connectAutoDispatch(
  ({ userState, planState, stripeState }) => ({
    userState,
    planState,
    stripeState
  }),
  {
    getProductPlanRequest,
    getCurrentPluginsRequest,
    getCurrentPluginsAddedRequest,
    getUserInfoAppRequest,
    userBuyPluginsRequest,
    stripeGetPricesRequest
  }
);

export default compose(withRouter, withConnect)(ListProduct);
