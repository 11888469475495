import React from "react";
import { TableCell, TableRow, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingBasketIcon from "@mui/icons-material/FileCopy";
import RemoveIcon from "@mui/icons-material/DeleteForever";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

export default function AZInsightAccounts({
  record,
  index,
  editMember,
  selectMarketplace,
  handleGetUserData,
  sendEmailInstructions,
  handleDeleteMember,
  recordSourcingHistoryMember,
  classes
}) {
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={record._id}>
      <TableCell padding="checkbox">{index + 1}</TableCell>
      <TableCell component="td" scope="row" padding="none">
        {`${record.firstName} ${record.lastName}`}
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        {record.email}
      </TableCell>
      <TableCell component="td" scope="row" padding="none" sx={{ textAlign: "center !important" }}>
        <FormControlLabel
          checked={record.disabledCalculatorSettings}
          control={
            <Checkbox
              onChange={e => editMember(e, record, "disabledCalculatorSettings")}
              className="checkbox"
              value={record.disabledCalculatorSettings}
              color="primary"
            />
          }
          label=""
        />
      </TableCell>
      <TableCell component="td" scope="row" padding="none" sx={{ textAlign: "center !important" }}>
        <FormControlLabel
          checked={record.accessHistory}
          control={
            <Checkbox
              onChange={e => editMember(e, record, "accessHistory")}
              className="checkbox"
              name="accessHistory"
              value={record.accessHistory}
              color="primary"
            />
          }
          label=""
        />
      </TableCell>
      <TableCell component="td" scope="row" padding="none" sx={{ textAlign: "center !important" }}>
        <FormControlLabel
          checked={record.accessGoogleAddon}
          control={
            <Checkbox
              onChange={e => editMember(e, record, "accessGoogleAddon")}
              className="checkbox"
              name="accessGoogleAddon"
              value={record.accessGoogleAddon}
              color="primary"
            />
          }
          label=""
        />
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        <FormControlLabel
          checked={record.recordSourcingHistory}
          control={
            <Checkbox
              onChange={e => recordSourcingHistoryMember(e, record)}
              className="checkbox"
              value={record.recordSourcingHistory}
              color="primary"
            />
          }
          label=""
        />
      </TableCell>
      {/* <TableCell component="td" scope="row" padding="none" sx={{ textAlign: "center !important" }}>
        <FormControlLabel
          checked={record.accessProducts}
          control={
            <Checkbox
              onChange={e => editMember(e, record, "accessProducts")}
              className="checkbox"
              name="accessProducts"
              value={record.accessProducts}
              color="primary"
            />
          }
          label=""
        />
      </TableCell>
      <TableCell component="td" scope="row" padding="none" sx={{ textAlign: "center !important" }}>
        <FormControlLabel
          checked={record.accessSeller}
          control={
            <Checkbox
              onChange={e => editMember(e, record, "accessSeller")}
              className="checkbox"
              name="accessSeller"
              value={record.accessSeller}
              color="primary"
            />
          }
          label=""
        />
      </TableCell> */}
      <TableCell component="td" scope="row" padding="none">
        <span style={{ textTransform: "capitalize" }}>{record.status || "Active"}</span>
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        {record.createdAt ? moment(record.createdAt).format("ll") : ""}
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        <Tooltip title="Clone the Calculator Settings from the main account to this account">
          <ShoppingBasketIcon
            onClick={() => selectMarketplace(record)}
            style={{ cursor: "pointer", marginRight: "5px" }}
          />
        </Tooltip>
        <EditIcon
          onClick={handleGetUserData(record)}
          style={{ cursor: "pointer", marginRight: "5px" }}
        />
        <RemoveIcon
          onClick={handleDeleteMember(record)}
          style={{ cursor: "pointer", marginRight: "5px", color: "red" }}
          className={classes.btnDelete}
        />
        <MailOutlineIcon
          onClick={() => sendEmailInstructions(record)}
          style={{ cursor: "pointer", marginRight: "5px" }}
        />
      </TableCell>
    </TableRow>
  );
}
