import { ModuleApp } from "containers";
import ListProduct from "../containers/app/plans";

const Routes = [
  {
    key: "USER_HOME",
    path: "/",
    exact: true,
    component: ListProduct,
    componentName: "ListProduct"
  },
  {
    key: "USER_PLANS",
    path: "/plans",
    exact: true,
    component: ListProduct,
    componentName: "ListProduct"
  },
  {
    key: "USER_LIST_MEMBER",
    path: "/asin-zen-insight/account-management",
    component: ModuleApp.User.ListMemberContainer,
    exact: true,
    componentName: "ListMemberContainer"
  },
  {
    key: "USER_ADD_NEW_MEMBER",
    path: "/user/add-new-member/:extension",
    component: ModuleApp.User.AddNewMemberContainer,
    exact: true,
    componentName: "AddNewMemberContainer"
  },
  {
    key: "USER_EDIT_MEMBER",
    path: "/user/edit-member/:id/:extension",
    component: ModuleApp.User.EditMemberContainer,
    exact: true,
    componentName: "EditMemberContainer"
  },
  {
    key: "USER_CHANGE_PASSWORD",
    path: "/user/change-password",
    component: ModuleApp.User.ChangePasswordContainer,
    exact: true,
    componentName: "ChangePasswordContainer"
  },
  {
    key: "PRODUCT_STRIPE",
    path: "/plans",
    component: ModuleApp.User.ListProduct,
    componentName: "ListProduct"
  },
  {
    key: "CUSTOMER_PORTAL",
    path: "/customer-portal",
    component: ModuleApp.User.CustomerPortal,
    componentName: "CustomerPortal"
  },
  {
    key: "SP_API_STATUS",
    path: "/user/amazon-integration",
    component: ModuleApp.User.SpApiStatus,
    componentName: "SpApiStatus"
  },
  {
    key: "AZ_API_TOP_RATED_SELLERS",
    path: "/az-api/top-rated-sellers",
    component: ModuleApp.AsinzenApi.TopRatedSellers,
    componentName: "TopRatedSellers"
  },
  {
    key: "AZ_API_SELLER_STOREFRONT",
    path: "/az-api/seller-storefront",
    component: ModuleApp.AsinzenApi.SellerStorefront,
    componentName: "SellerStorefront",
    sellerId: null,
    tab: 1
  },
  {
    key: "AZ_API_PRODUCT_VIEWER",
    path: "/az-api/product-viewer",
    component: ModuleApp.AsinzenApi.ProductViewer,
    componentName: "ProductViewer"
  },
  {
    key: "AZ_API_PRODUCT_TRACKING",
    path: "/az-api/product-tracking",
    component: ModuleApp.AsinzenApi.ProductTracking,
    componentName: "ProductTracking"
  },
  {
    key: "TEAM_HISTORY",
    path: "/user/team-history",
    component: ModuleApp.User.TeamHistory,
    componentName: "TeamHistory"
  },
  {
    key: "HISTORY_USAGE_STATISTICS",
    path: "/user/history-usage-statistics",
    component: ModuleApp.User.HistoryUsageStatistics,
    componentName: "HistoryUsageStatistics"
  },
  {
    key: "BUY_SELL_HISTORY",
    path: "/user/buy-sell-history",
    component: ModuleApp.User.BuySellHistory,
    componentName: "BuySellHistory"
  }
];

const MemberRoutes = [
  {
    key: "HOME",
    path: "/",
    exact: true,
    component: ModuleApp.User.TeamHistory,
    componentName: "TeamHistory"
  },
  {
    key: "TEAM_HISTORY",
    path: "/user/team-history",
    component: ModuleApp.User.TeamHistory,
    componentName: "TeamHistory"
  },
  {
    key: "AZ_API_TOP_RATED_SELLERS",
    path: "/az-api/top-rated-sellers",
    component: ModuleApp.AsinzenApi.TopRatedSellers,
    componentName: "TopRatedSellers"
  },
  {
    key: "AZ_API_SELLER_STOREFRONT",
    path: "/az-api/seller-storefront",
    component: ModuleApp.AsinzenApi.SellerStorefront,
    componentName: "SellerStorefront",
    sellerId: null,
    tab: 1
  },
  {
    key: "AZ_API_PRODUCT_VIEWER",
    path: "/az-api/product-viewer",
    component: ModuleApp.AsinzenApi.ProductViewer,
    componentName: "ProductViewer"
  },
  {
    key: "AZ_API_PRODUCT_TRACKING",
    path: "/az-api/product-tracking",
    component: ModuleApp.AsinzenApi.ProductTracking,
    componentName: "ProductTracking"
  },
  {
    key: "TEAM_HISTORY",
    path: "/user/team-history",
    component: ModuleApp.User.TeamHistory,
    componentName: "TeamHistory"
  }
];

export { Routes, MemberRoutes };
